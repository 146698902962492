<template>
  <DataTable ref="contactList" class="contact-profiles-list" :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        headers: [
          { text: 'Name', value: 'displayName', width: '12%', filter: true },
          {
            text: 'Email',
            value: 'email',
            width: '15%',
            filter: true,
          },
          {
            text: 'Phone',
            value: 'phone',
            width: '11%',
            filter: true,
          },
          {
            text: 'Host',
            value: 'hostAccount',
            width: '10%',
            align: 'center',
            filter: true,
            component: { name: 'dt-account-name', props: { field: 'hostAccount' } },
          },
          { text: 'Profile', value: 'accounts', width: '15%', filter: true, component: { name: 'accounts' } },

          { text: 'Total spent', value: 'totalSpent', width: '10%', align: 'center', component: { name: 'dt-price' } },
          { text: '# of completed bookings', value: 'completedBookings', width: '10%', align: 'center' },
          {
            text: 'Last enquiry (date)',
            value: 'lastEnquiryId',
            width: '10%',
            align: 'center',
            component: { name: 'dtDate', props: { format: 'DD MMM hh:mm a', setTimeZone: true } },
          },
        ],
        action: {
          hideEdit: true,
        },
        hideHeader: {
          created: true,
          updated: true,
        },
      },
    };
  },
};
</script>
<style lang="scss">
.contact-profiles-list {
  .profiles-images {
    max-width: 70%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .action-icon {
    height: 24px !important;
    width: 24px !important;
  }
}
</style>
